import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { polygon, bsc } from "@reown/appkit/networks";

// export const projectId = process.env.REACT_APP_PROJECT_ID;

export const projectId = "e4b1d7003a73ee99003691adc107b51d";
export const metadata = {
  name: "socail locket",
  description: "social locket Example",
  url: "https://reown.com/appkit",
  icons: ["https://assets.reown.com/reown-profile-pic.png"],
};
export const networks = [polygon, bsc];
export const wagmiAdapter = new WagmiAdapter({
  
  
  networks,
  projectId,
  ssr: true,
});
