// import AsyncStorage from "@react-native-async-storage/async-storage"

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export function loadString(key) {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function saveString(key, value) {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function save(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key) {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
}

/**
 * Burn it all to the ground.
 */
export function clear() {
  try {
    localStorage.clear();
  } catch (err) {
    console.log(err);
  }
}

/**
 * Get User bio from storage.
 *
 */
export function hasUserDetails() {
  try {
    const user = loadString("userDetails");
    if (user) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }
  return false;
}

export function hasPropertyWantedDetail() {
  try {
    const user = loadString("property-wanted");
    if (user) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }
  return false;
}
/**
 * Get User Id from storage.
 *
 */
export function getUserId() {
  try {
    const user = loadString("userDetails");
    if (user) {
      const userData = JSON.parse(user);
      return userData?.id;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}

/**
 * Get User Profile Image from storage.
 *
 */
export function getUserProfileImage() {
  try {
    const user = loadString("userDetails");
    if (user) {
      const userData = JSON.parse(user);
      return userData?.profile_image;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}

/**
 * Get User Full Name from storage.
 *
 */
export function getUserFullName() {
  try {
    const user = loadString("userDetails");
    if (user) {
      const userData = JSON.parse(user);
      return `${userData?.forename} ${userData?.surname}`;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}

/**
 * Get User bio from storage.
 *
 */
export function getUserBio() {
  try {
    const user = loadString("userDetails");
    if (user) {
      const userData = JSON.parse(user);
      return userData?.bio;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}

export function getUserEmail() {
  try {
    const user = loadString("userDetails");
    if (user) {
      const userData = JSON.parse(user);
      return userData?.email;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}

export function getPropertyWantedDetail() {
  try {
    const data = loadString("property-wanted");
    if (data) {
      return {
        isPropertyWanted: true,
        data: JSON.parse(data),
      };
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}
export function removePropertyWantedDetail() {
  try {
    remove("property-wanted");
  } catch (err) {
    console.log(err);
  }
}

export function removeHtmlTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, ""); // Regex to remove all HTML tags
}

export function getIsPostLike(data, user_id, post_id) {
  return (
    data &&
    data?.some(
      (like) =>
        like.user_id === Number(user_id) && like.post_id === Number(post_id)
    )
  );
}
export function getIsPostCommentLike(data, user_id, post_id, comment_id) {
  return (
    data &&
    data?.some(
      (like) =>
        like.user_id === Number(user_id) &&
        like.post_id === Number(post_id) &&
        like?.post_comment_id === Number(comment_id)
    )
  );
}
