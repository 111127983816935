import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "react-phone-number-input/style.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-datepicker/dist/react-datepicker.css";
import MessageModal from "./components/messageModal/MessageModal";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { AuthProvider } from "context/authContext";

import { QueryClient, QueryClientProvider } from "react-query";
import { CryptoProvider } from "context/cryptoContext";
import AppProvider from "context";

const stripe = loadStripe(
  "pk_test_51MFOETK7tBDZInmpfpMmh9jmUhnQP4LdSh5JoJtkR97ggoMUjgWAakUerjtkkzENrQ5v9GYmlXhfw7sto0iztagq001bHbR9Rs"
);

const initialOptions = {
  // clientId: "AeTpIBhsDmqUhRA8mIOh_dhyk2oBDxf3mAyjjrYy6d2CGy8RnaW1yRSAGRVHdwj-tRkK0VUpOfUB-_7N",
  clientId:
    "AdsAUgNaIt60lVV7J6zmtDAlual5uOiiLhdACcJMuB337et6OLV4WSd7dew23ES8xTeSVwUUp4-z5nTB",

  currency: "USD",
  components: "buttons",
  intent: "capture",
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
      // cacheTime: 1000 * 60 * 5,
      // staleTime: 1000 * 60 * 5,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    {/* <PayPalScriptProvider deferLoading={true} options={initialOptions}> */}
    <AppProvider>
      <Elements stripe={stripe}>
        <MessageModal>
          <AuthProvider>
            <CryptoProvider>
              <App />
            </CryptoProvider>
          </AuthProvider>
        </MessageModal>
      </Elements>
    </AppProvider>
    {/* </PayPalScriptProvider> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
