import { is, curryN, gte } from "ramda";

import axios from "axios";
import * as yup from "yup";
export const isNonEmptyArray = (arr) => {
  if (typeof arr === "object" && arr instanceof Array && arr?.length > 0) {
    return true;
  }

  return false;
};

export const isArray = (arr) => {
  if (typeof arr === "object" && arr instanceof Array) return true;

  return false;
};

export const isNonEmptyString = (str) => {
  if (typeof str === "string" && str?.length > 0) return true;

  return false;
};

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  );
});
export const in200s = isWithin(200, 299);
export const in400s = isWithin(400, 499);

export const formatDate = (timestamp) => {
  const localZone = Intl.DateTimeFormat().resolvedOptions();
  const empDate = new Date(timestamp);
  return new Intl.DateTimeFormat("en-US", {
    timeZone: localZone.timeZone,
    hourCycle: "h12",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(empDate);
};

export const formatOnlyDate = (timestamp) => {
  const localZone = Intl.DateTimeFormat().resolvedOptions();
  const empDate = new Date(timestamp);
  return new Intl.DateTimeFormat("en-US", {
    timeZone: localZone.timeZone,
    hourCycle: "h12",
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(empDate);
};

export const getInitials = (string) => {
  if (string) {
    const initials = string
      .split(" ")
      .map(([firstLetter]) => firstLetter)
      .filter((_, index, array) => index === 0 || index === array.length - 1)
      .join("")
      .toUpperCase();
    return initials;
  }
  return null;
};

export const isNumber = (num) => {
  if (
    (typeof num === "string" &&
      num?.length > 0 &&
      !Number.isNaN(Number(num))) ||
    (typeof num === "number" && !Number.isNaN(num))
  ) {
    return true;
  }

  return false;
};

export const parseStringArray = (arr) => {
  if (arr) {
    try {
      const str = JSON.parse(arr);
      if (typeof str === "string") {
        try {
          const jsonStr = str.replace(/'/g, '"'); // replace single quotes with double quotes
          const convertedArr = JSON.parse(jsonStr);
          return convertedArr;
        } catch (error) {
          const values = arr.split(",").map((value) => value.trim());
          return values;
        }
      }

      return str;
    } catch (e) {
      try {
        const jsonStr = arr.replace(/'/g, '"'); // replace single quotes with double quotes
        const convertedArr = JSON.parse(jsonStr);
        return convertedArr;
      } catch (error) {
        const values = arr.split(",").map((value) => value.trim());
        return values;
      }
    }
  }
  return null;
  // services = services.replace(/'/g, '"'); //replacing all ' with "
  // services = JSON.parse(services);
};

export const removeQuestionAndForwardSlash = (string) => {
  var modifiedString = string ? string.replace(/[\/?]/g, "-") : "";
  return modifiedString;
};

export const removeWhitespaces = (text) => {
  let str = text ?? "";
  str = str.replace("?", ""); // Remove the question mark
  str = str
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    })
    .replace(/\s/g, "");
  return str;
};
export const buildHypenString = (str) => {
  if (str) {
    return str.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
  }
  return "";
};

export const getAddress = (
  street,
  address1,
  address2,
  city,
  country,
  postalcode
) => {
  const addressComponents = [
    street,
    address1,
    address2,
    city,
    country,
    postalcode,
  ];
  const address = addressComponents
    .filter((component) => component !== null && component !== undefined)
    .join(", ");
  return address;
};
export const scrollTop = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 0);
};
export const scrollToTop = () => {
  const headingRef = document.getElementById("chaintop");

  if (headingRef) {
    headingRef.scrollIntoView({
      behavior: "smooth",
    });
  }
};

export function getIdValue(str) {
  if (str) {
    const val = Object.values(str);

    const valInStr = val.toString();

    const index = valInStr.split("_")[0];
    return index;
  }
  return "";
}

export function getAfterUnderScoreValue(str) {
  if (str) {
    const val = Object.values(str);
    const index = val[0].lastIndexOf("_");
    const result = val[0].substr(index + 1);
    return result;
  }
  return "";
}

export function getSelectedValues(objectWithOnes) {
  // const keysWithOnes = [];
  // Object.keys(objectWithOnes).forEach((k) => {
  //   if (objectWithOnes[k] === "1") keysWithOnes.push(k);
  // });
  // for (const key in objectWithOnes) {
  //   if (objectWithOnes[key] === "1") {
  //     keysWithOnes.push(key);
  //   }
  // }
  const keysWithOnes = [];
  // const obj = Object.keys(objectWithOnes);
  if (typeof objectWithOnes !== "undefined" && objectWithOnes !== null) {
    for (const key of Object.keys(objectWithOnes)) {
      if (objectWithOnes[key] === "1") {
        keysWithOnes.push(capitalizeFirstLetter(key));
      }
    }
    return keysWithOnes;
  } else {
    return null;
  }
  // if (keysWithOnes.length > 0) {
  //   return keysWithOnes;
  // }
  // return null;
}

export function formatDateMMDDYYYY(dateString) {
  // If the input is null or empty, use the current date
  if (!dateString) {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const year = now.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }

  // Create a new Date object from the input string
  const date = new Date(dateString);

  // Extract the month, day, and year values
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  // Format the date as "mm/dd/yyyy"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export function base64UrlToBlob(base64Url, fileType = "") {
  const base64Data = base64Url.replace(/^data:\w+\/\w+;base64,/, "");
  const uint8Array = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
  const blob = new Blob([uint8Array], { type: fileType });
  return blob;
}

export function capitalizeFirstLetter(string) {
  return !string ? "" : string.charAt(0).toUpperCase() + string.slice(1);
}

export function calculateAge(dateOfBirth) {
  const today = new Date();
  const [month, day, year] = dateOfBirth.split("/").map(Number);
  const birthDate = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // If the birth month hasn't occurred yet this year, or it's the current month but the day hasn't occurred, subtract 1 from age
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function checkSubscriptionStatus(userDataType) {
  return userDataType &&
    userDataType.length > 0 &&
    (userDataType[0]?.diamond === "1" ||
      userDataType[0]?.platinum === "1" ||
      userDataType[0]?.gold === "1" ||
      userDataType[0]?.enterprise === "1")
    ? false
    : true;
}

export function getUrlExtension(_url) {
  let ext = _url.split(/[#?]/)[0].split(".").pop();
  return ext ?? "".trim();
}

export const resizeHandleClasses = {
  bottom: "handle long-handle-horizontal bottom-handle",
  bottomLeft: "handle left-handle bottom-handle",
  bottomRight: "handle right-handle bottom-handle",
  left: "handle long-handle left-handle",
  right: "handle long-handle right-handle",
  top: "handle long-handle-horizontal top-handle",
  topLeft: "handle left-handle top-handle",
  topRight: "handle right-handle top-handle",
};

export const baseUrl = process.env.REACT_APP_BASE;
// export const baseUrl = window.location.origin;

export const customTooltipStyle = {
  padding: "5px",
  margin: "0",
};

const API_KEY = "f563792e3765d77c738f4ebf";

export const getConversionRate = async (fromCurrency, toCurrency) => {
  try {
    const response = await axios.get(
      `https://v6.exchangerate-api.com/v6/${API_KEY}/latest/${fromCurrency}`
    );

    const conversionRate = response?.data?.conversion_rates?.[toCurrency];

    if (!conversionRate) {
      throw new Error(`Unable to find conversion rate for ${toCurrency}`);
    }

    return conversionRate;
  } catch (error) {
    console.error("Error fetching conversion rate:", error);
    throw error;
  }
};
export const validationSchema = yup.object().shape({
  name: yup.string().required("Template name is required"),
  file: yup.array().min(1, "Template file is required"),
});

export const validateSchema = (boolVal) => {
  return {
    shouldValidate: boolVal,
  };
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(number);
};

export const uniqueId = () => {
  return new Date().getTime() + Math.floor(Math.random() * 10000);
};

export const get_popup_session_storage = (type) => {
  const data = sessionStorage.getItem("popup");
  if (data) {
    const parsedData = JSON.parse(data);
    if (parsedData[type]) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const set_popup_session_storage = (type) => {
  const data = sessionStorage.getItem("popup");


  if (data) {
    const parsedData = JSON.parse(data);
    parsedData[type] = true;
    sessionStorage.setItem("popup", JSON.stringify(parsedData));
  } else {
    const obj = {};
    obj[type] = true;
    sessionStorage.setItem("popup", JSON.stringify(obj));
  }
};



export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;






