import { useAppKitAccount } from "@reown/appkit/react";
import { useAccount } from "wagmi";

const { createContext, useContext } = require("react");

const CryptoContext = createContext();

export const CryptoProvider = ({ children }) => {
  const { chainId, address, isConnected } = useAccount();

  
  
  return (
    <CryptoContext.Provider
      value={{
        address,
        isConnected,
        chainId,
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};

export const useCrypto = () => {
  return useContext(CryptoContext);
};
