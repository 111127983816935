import React from "react";

const CustomLoader = ({ hide_class }) => {
  return (
    <>
      <div
        className={`custom_suspense_loader_container ${
          hide_class ? "low_index" : "high_index"
        }`}
      >
        <div
          className={`custom_suspense_loader_container--sub ${
            hide_class ? "low_index" : "high_index"
          }`}
        >
          <div class="custom_suspense_loader"></div>
        </div>
      </div>
    </>
  );
};

export default CustomLoader;
