import { QueryClient, QueryClientProvider } from "react-query";
import { networks, projectId, wagmiAdapter, metadata } from "./walletConfig";

import { WagmiProvider } from "wagmi";
import { createAppKit } from "@reown/appkit/react";

import { polygon } from "@reown/appkit/networks";
import { useEffect } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  },
});

const AppProvider = ({ children }) => {
  useEffect(() => {
    createAppKit(
      {
        adapters: [wagmiAdapter],
        networks,
        projectId,
        defaultNetwork: polygon,
        metadata,

        features: {
          analytics: true,
          socials: true,
          email: false,
        },
      },
      []
    );
  }, []);
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

export default AppProvider;
